






















































































































import useNumber from "@/use/number";
import useUser from "@/use/user";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  watch,
  nextTick,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";
import { Dictionary } from "vue-router/types/router";
import useFuelCard, { ProductType } from "@/use/fuelCard";
import useEmissionStandard from "@/use/emissionStandard";
import useColor from "@/use/color";
import mime from "mime-types";
import moment from "moment";
import fileDownload from "js-file-download";
import { watchDebounced } from "@vueuse/shared";

export default defineComponent({
  components: {
    MCompanyFilter: () => import("@/components/molecules/m-company-filter.vue"),
    MInvoiceSelect: () => import("@/components/molecules/m-invoice-select.vue"),
    MDatePicker: () => import("@/components/molecules/m-date-picker.vue"),
  },
  props: {
    short: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { root }) {
    const { hasAccessTo, hasManyCompanies } = useUser({ root });
    const { formatNumber } = useNumber();

    const logos = {
      orlen: require("@/assets/img/brands/orlen.svg"),
      bp: require("@/assets/img/brands/bp.svg"),
      flotex: require("@/assets/img/brands/flotex.svg"),
    };

    const { productTypes } = useFuelCard();
    const { emissionStandards } = useEmissionStandard();
    const { isDark } = useColor();

    const model = reactive({
      company: null as any,
      registrationNumber: null as null | string,
      productType: null as null | ProductType,
      invoice: null as null | { id: string; number: string },
      date: {
        from: null as null | Date,
        to: null as null | Date,
      },
    });

    const state = reactive({
      headers: computed(() => {
        const headers = [];

        headers.push({
          text: "Pojazd",
          value: "vehicle",
          sortable: false,
          width: 200,
        });
        headers.push({
          text: "Norma emisji spalin",
          value: "emissionStandard",
          sortable: false,
          width: 150,
        });
        headers.push({
          text: "Przebieg pojazdu",
          value: "mileage",
          sortable: false,
          width: 150,
        });
        if (hasAccessTo.value("employee"))
          headers.push({ text: "Firma", value: "company", sortable: false });
        headers.push({
          text: "Suma",
          value: "total",
          sortable: false,
          width: 150,
        });

        return headers;
      }),
      childHeaders: computed(() => {
        const headers = [];

        headers.push({ text: "", value: "type", width: 1, sortable: false });
        headers.push({ text: "Nazwa produktu", value: "productName" });
        if (!model.invoice)
          headers.push({ text: "Numer faktury", value: "invoice" });
        headers.push({ text: "Przebieg", value: "mileage" });
        headers.push({ text: "Data", value: "date" });
        headers.push({ text: "Cena (brutto)", value: "price" });
        headers.push({ text: "Ilość", value: "amount" });
        headers.push({ text: "Wartość (netto)", value: "total", width: 150 });

        return headers;
      }),
      items: [] as any[],
      options: {
        page: 1 as number,
        itemsPerPage: 5 as number,
        sortBy: [] as string[],
        sortDesc: [] as boolean[],
        groupBy: [] as string[],
        groupDesc: [] as boolean[],
        multiSort: false as boolean,
        mustSort: false as boolean,
      },
      total: 0,
      loading: false,
      queryLoading: false,
      loaded: false,
    });

    watch(
      () => state.options,
      (o) => {
        if (root.$route.name === "panel.fuel-card-transaction.list") {
          const query: Dictionary<
            string | (string | null)[] | null | undefined
          > = {};

          if (o.page) query["transactions.page"] = o.page.toString();
          if (o.itemsPerPage)
            query["transactions.items_per_page"] = o.itemsPerPage.toString();
          if (o.sortBy && o.sortBy.length)
            query["transactions.sort_by"] = o.sortBy.join(",");
          if (o.sortDesc && o.sortDesc.length)
            query["transactions.sort_desc"] = o.sortDesc
              .map((i) => (i ? "true" : "false"))
              .join(",");

          root.$router.replace({ query }).catch(() => []);
        }
      }
    );

    onMounted(() => {
      if (root.$route.name === "panel.fuel-card-transaction.list") {
        const query = root.$route.query;

        state.queryLoading = true;

        nextTick(() => {
          if (
            query["transactions.page"] &&
            typeof query["transactions.page"] === "string"
          )
            state.options.page = parseInt(query["transactions.page"]);
          if (
            query["transactions.items_per_page"] &&
            typeof query["transactions.items_per_page"] === "string"
          )
            state.options.itemsPerPage = parseInt(
              query["transactions.items_per_page"]
            );
          if (
            query["transactions.sort_by"] &&
            typeof query["transactions.sort_by"] === "string"
          )
            state.options.sortBy = query["transactions.sort_by"]
              .split(",")
              .filter((i) => typeof i === "string") as string[];
          if (
            query["transactions.sort_desc"] &&
            typeof query["transactions.sort_desc"] === "string"
          )
            state.options.sortDesc = query["transactions.sort_desc"]
              .split(",")
              .map((i) => i === "true");

          setTimeout(() => (state.queryLoading = false), 2000);
        });
      }
    });

    const filter = computed(() => ({
      company: model.company ? model.company.id : undefined,
      registrationNumber: model.registrationNumber || undefined,
      date:
        model.date.from || model.date.to
          ? {
              from: model.date.from || undefined,
              to: model.date.to || undefined,
            }
          : undefined,
      productType: model.productType || undefined,
      invoice: model.invoice ? model.invoice.id : undefined,
    }));

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get("fuel-card", {
          params: {
            sortBy,
            sortDesc,
            page,
            itemsPerPage,
            filter: filter.value,
          },
        })
        .then(({ data: { purchases, total } }) => {
          state.items = purchases.map((purchase: any, index: number) => ({
            id: index,
            actions: null,
            ...purchase,
          }));
          state.total = total;
        })
        .catch(() => {
          console.log("error");
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    const fetchExport = () =>
      new Promise<void>((resolve, reject) => {
        const axiosInstance = root.$store.getters[
          "api/getInstance"
        ] as AxiosInstance;

        axiosInstance
          .get("fuel-card/export", {
            params: filter.value,
            responseType: "blob",
          })
          .then(({ data }) => {
            fileDownload(
              data,
              `Zestawienie_transakcji_CleverFleet_${moment().format(
                "YYYY-MM-DD_HH-mm-ss"
              )}.${mime.extension(data.type)}`
            );
            resolve();
          })
          .catch(reject);
      });

    watchDebounced([model, () => state.options], fetchData, {
      deep: true,
      immediate: true,
      debounce: 500,
      maxWait: 5000,
    });

    watch(model, () => (state.options.page = 1), { deep: true });

    const getTotal = (vehicle: any, group?: string) =>
      vehicle?.fuelCardPurchases
        ?.filter((purchase: any) => !group || purchase.productType === group)
        .map((purchase: any) => purchase.total)
        .reduce((prev: any, cur: any) => prev + cur, 0) || 0;

    return {
      hasAccessTo,
      hasManyCompanies,
      formatNumber,
      logos,
      productTypes,
      emissionStandards,
      isDark,
      model,
      state,
      getTotal,
      fetchExport,
    };
  },
});
