export enum ProductType {
  Petrol = 'petrol',
  Carwash = 'carwash',
  Tolls = 'tolls',
  Other = 'other'
}

export default function useFuelCard() {
  const productTypes: {
    text: string;
    value: ProductType;
  }[] = [
    { text: 'Paliwo', value: ProductType.Petrol },
    { text: 'Myjnia', value: ProductType.Carwash },
    { text: 'Opłaty drogowe', value: ProductType.Tolls },
    { text: 'Inne', value: ProductType.Other }
  ]

  return { productTypes }
}